<template>
  <b-modal id="popup-device" ref="popup-device" centered="centered" :title="(deviceForm.id ? 'Modifier': 'Ajouter') + ` un appareil`" @ok="create" no-close-on-backdrop>
      <div v-if="isCreatingAccessMethodType">
          <div class="text-center flex-center">
            <div class="loading-bg-inner">
              <div class="loader">
                <div class="outer"></div>
                <div class="middle"></div>
                <div class="inner"></div>
              </div>
            </div>
            <div class="mt-5">
              <br /><br /><br />
              Chargement...
            </div>
          </div>
      </div>
      <validation-observer v-else ref="formDevice">
          <b-row>
              <b-col cols="12" class="d-flex mb-1">
                  <b-form-group label="Libellé *" label-for="label" style="flex:1">
                    <validation-provider #default="{ errors }" name="label" rules="required">
                    <b-form-input
                        id="label"
                        v-model="deviceForm.label"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                        v-on:keyup.enter="create"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" class="d-flex">
                  <b-form-group label="Marque *" label-for="brand" style="flex:1">
                    <validation-provider #default="{ errors }" name="brand" rules="required">
                      <v-select id="brand" :reduce='(type) => type.id' v-model='deviceForm.brandId' :options='brandsList' label="label" :state="errors.length > 0 ? false : null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="d-flex">
              <b-form-group label="Type d'appareil *" label-for="DeviceType" style="flex:1">
                <validation-provider #default="{ errors }" name="DeviceType" rules="required">
                  <v-select id="deviceType" :reduce='(type) => type.id' v-model='deviceForm.deviceTypeId' :options='deviceTypesList' label="label" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" class="d-flex">
                  <b-form-group label="Date *" label-for="date" style="flex:1">
                    <validation-provider #default="{ errors }" name="date" rules="required">
                      <date-range-picker class="w-100" ref="picker" id="accountingExportDuration" :single-date-picker="true" :show-week-numbers="true" :timePicker24Hour='true' :timePicker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="filterRange" :auto-apply="true" :state="errors.length > 0 ? false : null"></date-range-picker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" class="d-flex">
                  <b-form-group label="Client *" label-for="customer" style="flex:1">
                    <validation-provider #default="{ errors }" name="customer" rules="required">
                      <v-select id="customer" :reduce='(customer) => customer.id' v-model='customerId' :options='customersList' label="firstName" :filter="searchCustomer" :state="errors.length > 0 ? false : null">
                        <template #option='{ firstName, lastName }'>
                          {{ firstName + ' ' + lastName }}
                        </template>
                        <template #selected-option='{ firstName, lastName }'>
                          {{ firstName + ' ' + lastName }}
                        </template>
                        <template v-slot:no-options=''>
                          Aucun r&eacute;sultat trouv&eacute;
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row v-if="customerId">
              <b-col cols="12" class="d-flex">
                  <b-form-group label="Bassin *" label-for="pool" style="flex:1">
                    <validation-provider #default="{ errors }" name="pool" rules="required">
                      <v-select id="pool" :reduce='(type) => type.id' v-model='deviceForm.poolId' :options='filteredPools' label="label">
                        <template v-slot:no-options=''>
                          Aucun r&eacute;sultat trouv&eacute;
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
        </validation-observer>
        <template #modal-footer="{ ok, cancel }">
          <div class="w-100 mx-0">
              <b-button class="float-right" variant="primary" @click="ok()">{{deviceForm.id ? 'Modifier': 'Ajouter'}}</b-button>
              <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
          </div>
      </template>
  </b-modal>
  </template>
  
  <script>
  import {
    ValidationProvider,
    ValidationObserver,
    configure,
    localize,
  } from "vee-validate";
  import { required, url, email } from "@validations";
  import { mapGetters, mapActions } from "vuex";
  import Ripple from "vue-ripple-directive";
  import "vue-swatches/dist/vue-swatches.css";
  import vSelect from 'vue-select'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  configure({
    // Generates an English message locale generator
    generateMessage: localize("fr", {
      messages: {
        url: "Ce champ doit être une URL",
        required: "Ce champ est requis",
        email: "Ce champ doit être un email",
      },
    }),
  });
  localize("fr");
  var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)
const config = {
  direction: 'ltr',
  format: 'dd/mm/yyyy HH:MM',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Période personnalisée',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  firstDay: 1
}

    let today = new Date()

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)

    var defaultRange = {
      'Aujourd\'hui':[ today,today ],
      'Hier':[ yesterday, yesterday ],
      'Mois dernier': [
        new Date(
          dayjs()
            .subtract(1, 'month')
            .startOf('month')
            .hour(12)
        ),
        new Date(dayjs().subtract(1, 'month').endOf('month'))
      ],
      'Année dernière': [
        new Date(
          dayjs()
            .subtract(1, 'year')
            .startOf('year')
            .hour(12)
        ),
        new Date(
          dayjs()
            .subtract(1, 'year')
            .endOf('year')
        )
      ],
    }
  
  export default {
    data() {
      return {
        required,
        url,
        email,
        customerId: null,
        deviceForm: {
        id: 0,
        label: "",
        date: new Date().toISOString(),
        deviceTypeId: null,
        brandId: null,
        poolId: null
      },
      config: config,
      defaultRange: defaultRange,
      startDateFiscalYear: {
        startDate: null,
        endDate: null
      },
      endDateFiscalYear: {
        startDate: null,
        endDate: null
      },
      filterRange:{
        startDate: new Date(),
      },
      };
    },
    methods: {
      searchCustomer(options, search) {
        return options.filter(option => {
          const fullName = (option.firstName+' '+option.lastName).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, "").trim().toLowerCase()
          const searchNormalized = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, "").trim().toLowerCase()
          if (fullName.includes(searchNormalized)) {
            return option;
          }
        });
	},
      popupCreateDeviceEvent(){
        this.fetchDeviceTypesList()
        this.fetchCustomersList()
        this.fetchBrandsList()
        this.fetchPoolsList()
      },
      create(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.formDevice.validate().then((success)=> {
          if (success) {
            this.createDevice({label: this.deviceForm.label, deviceTypeId: this.deviceForm.deviceTypeId, poolId: this.deviceForm.poolId, brandId: this.deviceForm.brandId, date: this.deviceForm.date}).then((res)=>{
              this.$emit('popupDeviceClose', {id: res.id})
              this.$bvModal.hide('popup-device')
            })
          }
        })

      },
      ...mapActions(["createDevice", "updateAccessMethodType", "fetchAccessMethodType", "deleteAccessMethodType", "fetchDeviceTypesList", "fetchBrandsList", "fetchCustomersList", "fetchPoolsList", ]),
    },
    computed: {
      ...mapGetters(["isCreatingAccessMethodType", "deviceTypesList", "brandsList", "customersList", "poolsList"]),
      filteredPools(){
      let newArray = []
      if (this.customerId) {
        newArray = this.poolsList.filter(elem => elem.customerId === this.customerId)
      }
      return newArray
    },
    },
    watch:{
      "customerId": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.deviceForm.poolId = null
        }
      },
      'filterRange': function(newVal){
        this.deviceForm.date = new Date(newVal.startDate).toISOString()
      },
    },
    
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
      DateRangePicker
    },
    directives: {
      Ripple,
    },
  };
  </script>