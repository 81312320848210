<template lang="pug">
  div
    b-row.m-0.justify-content-center
      b-col.content-header(cols="9")
        h2.content-header-title.float-left.m-0.pr-1.mr-1
          | {{ $route.meta.pageTitle }}
        .content-header-search.mr-1
          h3.m-0 {{ interventionForm.label }}
        .content-header-actions.d-flex
          feather-icon.cursor(icon="XIcon", size="24", @click="cancel()")
    .content-loader-center.m-0.h-100(v-if="isLoadingCustomer || isLoadingIntervention || isCreatingIntervention || isUpdatingIntervention")
      .text-center.flex-center
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des d&eacute;tails de l'intervention...
    b-row.content-scrollable-sticky.m-0.h-100.justify-content-center(v-else)
      b-col.content-scrollable-sticky-main(cols="9")
        validation-observer(ref="formCreateIntervention")
          b-row(v-if="interventionForm.id").my-0 
           b-col.py-0.mb-1
            b-form-group(
                label="Cette intervention est ",
                label-for="statut"
              )
                v-select#statut(
                  :reduce="(type) => type.id",
                  v-model="interventionForm.status",
                  :options="status"
                  label="label"
                ) 
                  template(#selected-option-container="{ option, deselect, multiple, disabled }")
                    .d-flex.align-items-center
                      .pin-status(:style="'background-color:'+(option.color!=='' ? option.color : '#0c3571')+' !important'")
                      div {{option.label}}
                  template(v-slot:option="option")
                    .d-flex
                      .pin-status(:style="'background-color:'+(option.color!=='' ? option.color : '#0c3571')+' !important'")
                      div {{option.label}}
                  template(v-slot:no-options="")
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
          b-row.my-0
            b-col.py-0.mb-1(cols="6")
              b-form-group(
                label="Personne en charge de l'intervention *",
                label-for="collaborator"
              )
                validation-provider(
                  #default="{ errors }",
                  name="collaborator",
                  rules="required"
                )
                  v-select#collaborator(
                    :state="errors.length > 0 ? false : null",
                    :reduce="(type) => type.id",
                    v-model="interventionForm.collaboratorId",
                    :options="collaboratorsList"
                    label="firstName"
                    :filter="searchCustomer"
                  )
                    template(#option="{ firstName, lastName }")
                      | {{ firstName + " " + lastName }}
                    template(#selected-option="{ firstName, lastName }")
                      | {{ firstName + " " + lastName }}
                    template(v-slot:no-options="")
                      template
                        | Aucun r&eacute;sultat trouv&eacute;
                  small.text-danger {{ errors[0] }}
            b-col.py-0.mb-1(cols="6")
              b-form-group(label="Client *", label-for="customer")
                validation-provider(
                  #default="{ errors }",
                  name="customer",
                  rules="required"
                )
                  v-select#customer(
                    :state="errors.length > 0 ? false : null"
                    :reduce="(client) => client.id"
                    v-model="interventionForm.customer.id"
                    :options="customersList"
                    label="firstName"
                    :filter="searchCustomer"
                  )
                    template(#option="{ firstName, lastName }")
                      | {{ firstName + " " + lastName }}
                    template(#selected-option="{ firstName, lastName }")
                      | {{ firstName + " " + lastName }}
                    template(v-slot:no-options="")
                      template
                        | Aucun r&eacute;sultat trouv&eacute;
                  small.text-danger {{ errors[0] }}
          b-row.my-0
            b-col.py-0.mb-1(cols="12")
              b-form-group(label="Bassin *", label-for="pool")
                validation-provider(
                  #default="{ errors }",
                  name="pool",
                  rules="required"
                )
                  v-select#pool(
                    :state="errors.length > 0 ? false : null",
                    :reduce="(pool) => pool.id",
                    v-model="interventionForm.poolId",
                    :options="filteredPools"
                  )
                    template(v-slot:no-options="")
                      template
                        | Aucun r&eacute;sultat trouv&eacute;
                  small.text-danger {{ errors[0] }}
          vs-divider.mt-2.mb-1(color="#0096C7") Informations sur l'intervention
          b-row.my-0 
            b-col.py-0.mb-1(cols="12")
              b-form-group(label="Type d'intervention*", label-for="label")
              validation-provider(
                #default="{ errors }",
                name="interventionType",
                rules="required"
              )
                v-select#interventionType(
                  :state="errors.length > 0 ? false : null",
                  :reduce="(type) => type.id",
                  v-model="interventionForm.interventionTypes.id",
                  :options="interventionTypesList"
                )
                  template(v-slot:no-options="")
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-row.my-0
            b-col.py-0.mb-1(cols="12")
              p.mb-0(v-if="!interventionForm.id") Réccurence
              b-form-checkbox.mb-1(v-model='displayRecurrent' name='customerId' switch v-if="!interventionForm.id")
              b-form-group(label="Date *", label-for="label" v-if="!displayRecurrent")
                validation-provider(
                  #default="{ errors }",
                  name="date",
                  rules="required"
                )
                  .d-flex.align-items-center.mb-1(:class="recurrentInterventionList.length > 1 ? 'containerInputDatePicker' : '' ")
                    date-range-picker.w-50(
                      ref='simpleRecurrent'
                      class="inputDatePicker"
                      :single-date-picker="true"
                      time-picker-24-hour
                      :show-dropdowns="true"
                      :time-picker="true"
                      :ranges="defaultRangeSingle"
                      :append-to-body="true"
                      :locale-data="config2"
                      v-model="interventionDate"
                      :auto-apply="true"
                      :state="errors.length > 0 ? false : null",
                    )
                  small.text-danger {{ errors[0] }}
              b-form-group(v-else)
                .d-flex.align-items-center.mb-1
                  p.mr-1.mb-0 Toutes les 
                  b-form-input.mr-1#weeks(v-model="numberRecurrent" min="1" autocomplete="nope" aria-autocomplete="nope" type="number")
                  p.mr-1.mb-0 semaines le
                  v-select.mr-1(
                    style="width: 150px"
                    v-model="dayRecurrent",
                    :options="days"
                    label="label"
                  )
                  p.mr-1.mb-0 sur la période
                  validation-provider(
                    #default="{ errors }",
                    name="date",
                    rules="required"
                  )
                    .d-flex.align-items-center
                      date-range-picker.w-100.mr-1(
                        :timePicker="false"
                        :show-dropdowns="true"
                        :append-to-body="true"
                        :ranges="defaultRangeRecurrent"
                        :locale-data="config1"
                        v-model="dateRangeRecurrentIntervention"
                        :auto-apply="true"
                        :state="errors.length > 0 ? false : null",
                      )
                  p.mb-0 à
                  b-time.ml-0(hide-header locale="fr" v-model="timeRecurrent")
                b-form-group(label="Interventions", label-for="label" v-for="(intervention, index) in recurrentInterventionList" :key="index+'-'+keyDateList")
                  .d-flex.align-items-center.mb-1(:class="recurrentInterventionList.length > 1 ? 'containerInputDatePicker' : '' ")
                    date-range-picker.w-100(
                      ref="addSimpleIntervention"
                      class="inputDatePicker"
                      :single-date-picker="true"
                      :show-week-numbers="true"
                      :timePicker24Hour="true"
                      :show-dropdowns="true"
                      :timePicker="true"
                      opens="right"
                      :append-to-body="true"
                      :locale-data="config2"
                      :ranges="null"
                      v-model="recurrentInterventionList[index]"
                      :auto-apply="true"
                    )
                    .bg-primary.containerIcon(@click="deleteRecurentIntervention(index)")
                      feather-icon(icon='Trash2Icon')
            
          b-row.my-0
            b-col.py-0.mb-1(cols="9")
              b-form-group(label="Tâches à réaliser", label-for="poolAccessType")
                b-button.my-1.add-step(variant="outline-primary" @click="popupCreateStep('')")
                  | Ajouter une étape
                  feather-icon.ml-1(icon='PlusIcon')
                nested-draggable(:steps="interventionForm.steps" :isEdit="interventionForm.id ? true : false" :isMissions="false" @popup-mission="popupCreateMission" @deleteStepInSteps='deleteStepInIntervention' @deleteMissionInSteps="deleteMissionInIntervention" @editStep="popupCreateStep" @editMission="popupCreateMission")
          vs-divider.mt-2.mb-1(color="#0096C7") Indications
          b-row.my-0
            b-col.py-0.mb-1(cols="12")
              b-form-group(
                label="Indications pour le technicien",
                label-for="indications"
              )
                vs-textarea(
                  v-model="interventionForm.indications",
                  height="140px",
                  style="min-height: 140px; font-size: 1rem"
                )
            b-row.my-0
              b-col.py-0.mb-1(cols='12')
                b-form-group(label='Photos de l\'intervention' label-for='pictures')
                  vue-dropzone#pictures(ref='imgDropZone' @vdropzone-file-added="uploadFileAdded" :destroyDropzone="false" :options='dropzoneOptions' @vdropzone-removed-file="removeFile")
        b-row.my-0
          b-col.py-0(cols="12")
            .d-flex.justify-content-between.mt-2(
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            )
              .d-flex
                b-button(variant="outline-primary", @click="cancel()")
                  feather-icon(icon="ArrowLeftIcon")
                  | Annuler
                b-button.btn-icon.ml-2(
                  v-if="interventionForm.id",
                  variant="outline-danger",
                  @click="archiveCustomerLocal(interventionForm.id, interventionForm.firstName + ' ' + interventionForm.lastName)"
                )
                  feather-icon(icon="ArchiveIcon")
              b-button(variant="primary", @click="create")
                feather-icon(icon="SaveIcon", v-if="interventionForm.id")
                feather-icon(icon="PlusIcon", v-else)
                | {{ interventionForm.id ? "Modifier" : "Ajouter" }}
        popup-step(ref="popupStep", @popupStepClose="editOrAddStep" @deleteStepInSteps="deleteStepInIntervention")
        popup-mission(ref="popupMission", @popupMissionClose="editOrAddMission" @deleteMissionInSteps="deleteMissionInIntervention")
  </template>
<script>
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import nestedDraggable from "../../components/draggable/nested-draggable";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

const config1 = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
const config2 = {
  direction: "ltr",
  format: "dd/mm/yyyy - HH:MM",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
let today = new Date();

let yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

var defaultRangeRecurrent = {
  "Pendant deux semaines": [
    today,
    new Date(dayjs().add(2, "week").endOf("week").add(1, "day")),
  ],
  "Pendant trois semaines": [
    today,
    new Date(dayjs().add(3, "week").endOf("week").add(1, "day")),
  ],
  "Pendant un mois": [
    today,
    new Date(dayjs().add(1, "month").endOf("week").add(1, "day")),
  ],
  "Pendant trois mois": [
    today,
    new Date(dayjs().add(3, "month").endOf("week").add(1, "day")),
  ],
  "Pendant un an": [today, new Date(dayjs().add(1, "year"))],
};
var defaultRangeSingle = {
  "Aujourd'hui": [today, today],
  "Demain": [tomorrow, tomorrow],
  "Dans une semaine": [new Date(dayjs().add(1, "week")), new Date(dayjs().add(1, "week"))],
  "Dans un mois": [new Date(dayjs().add(1, "month")), new Date(dayjs().add(1, "month"))],
  "Dans un an": [new Date(dayjs().add(1, "year")), new Date(dayjs().add(1, "year"))],
};

// Créez un nouvel objet Date
var dateActuelle = new Date();

// Obtenez l'heure actuelle
var heure = dateActuelle.getHours();

// Obtenez les minutes actuelles
var minutes = dateActuelle.getMinutes();

configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

import PopupStep from "@/components/step/PopupStepFromIntervention";
import PopupMission from "@/components/mission/PopupMissionFromIntervention";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  name: "app",
  data() {
    return {
      keyDateList: 0,
      is_destroying: false,
      config1: config1,
      config2: config2,
      defaultRangeRecurrent: defaultRangeRecurrent,
      defaultRangeSingle: defaultRangeSingle,
      startDateFiscalYear: {
        startDate: null,
        endDate: null,
      },
      endDateFiscalYear: {
        startDate: null,
        endDate: null,
      },
      filterRecurrenceRange: {
        startDate: dayjs(),
      },
      days: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
      displayAddress: false,
      dropzoneOptions: {
        dictRemoveFileConfirmation:
          "Êtes-vous sûr de vouloir supprimer cette photo ? Cette action est irréversible.",
        autoQueue: false,
        url: "Testttt",
        maxFilesize: 10000000,
        thumbnailWidth: 300,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        dictRemoveFile: `<p class='m-0 p-0 text-default'>Supprimer</p>`,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i><p class='text-default'>Faites glisser vos fichiers ou cliquez ici</p>`,
        manuallyAddFile: true,
        autoProcessQueue: false,
      },
      displayRecurrent: false,
      removedFile: false,
      required,
      url,
      email,
      dataOrigine: {},
      recurrentInterventionList: [],
      interventionDate: {},
      dateRangeRecurrentIntervention: { startDate: null, endDate: null },
      timeRecurrent: `${heure}:${minutes}`,
      dateRecurrent: {},
      dayRecurrent: "",
      numberRecurrent: 1,
      initialPhoto: [],
      status: [
        { id: 0, label: "A faire", color: "#0096C7" },
        { id: 1, label: "En cours", color: "#E3B505" },
        { id: 2, label: "Clôturée", color: "#B4B1B1" },
      ],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.formInterventionEvent(this.$route.params.id);
    } else {
      this.formInterventionEvent();
    }
    this.fetchCustomersList();
    this.fetchCollaboratorsList();
    this.fetchInterventionTypesList();
    this.fetchPoolsList();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.setInputDate();
  },
  destroyed() {
    this.is_destroying = false;
  },
  beforeDestroy() {
    this.is_destroying = true;
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    getDatesInRange(startDate, endDate, intervalWeeks, targetDayOfWeek) {
      const daysOfWeek = [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
      ];
      const targetDayIndex = daysOfWeek.indexOf(targetDayOfWeek.toLowerCase());

      if (targetDayIndex === -1 || intervalWeeks <= 0) {
        return [];
      }

      const datesInRange = [];
      const currentDate = new Date(startDate);
      currentDate.setHours(0, 0, 0, 0);

      while (currentDate <= endDate) {
        if (currentDate.getDay() === targetDayIndex) {
          datesInRange.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Filter dates based on intervalWeeks
      const filteredDates = datesInRange.filter(
        (date, index) => index % (intervalWeeks * 7) === 0
      );

      return filteredDates;
    },

    setInputDate() {
      if (this.interventionForm.id) {
        this.interventionDate = {
          startDate: new Date(this.interventionForm.dateTime),
          endDate: null,
        };
      } else {
        this.interventionDate = {
          startDate: new Date(),
          endDate: null,
        };
      }
    },
    searchCustomer(options, search) {
      return options.filter((option) => {
        const fullName = (option.firstName + " " + option.lastName)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/gi, "")
          .trim()
          .toLowerCase();
        const searchNormalized = search
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/gi, "")
          .trim()
          .toLowerCase();
        if (fullName.includes(searchNormalized)) {
          return option;
        }
      });
    },
    deleteRecurentIntervention(index) {
      this.recurrentInterventionList.splice(index, 1);
      this.keyDateList++;
    },
    async generateRecurrenteInterventions() {
      this.recurrentInterventionList = [];
      if (
        this.numberRecurrent > 0 &&
        this.dayRecurrent &&
        this.timeRecurrent &&
        this.dateRangeRecurrentIntervention.startDate &&
        this.dateRangeRecurrentIntervention.endDate
      ) {
        const hours = this.timeRecurrent.split(":")[0];
        const minutes = this.timeRecurrent.split(":")[1];
        let startDate = dayjs(this.dateRangeRecurrentIntervention.startDate)
          .hour(hours)
          .minute(minutes); // Date de début
        const endDate = dayjs(this.dateRangeRecurrentIntervention.endDate)
          .hour(hours)
          .minute(minutes); // Date de fin
        let dayOfWeekToFind =
          this.days.findIndex((elem) => elem == this.dayRecurrent) + 1;
        let dayOfStart = dayjs(startDate).day();
        if (dayOfWeekToFind == 7) {
          dayOfWeekToFind = 0;
        }
        const interval = this.numberRecurrent;
        startDate = startDate.day(dayOfWeekToFind);
        if (dayOfWeekToFind < dayOfStart) {
          startDate = startDate.add(1, "week");
        }
        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
          if (startDate.get("day") === dayOfWeekToFind) {
            this.recurrentInterventionList.push({
              startDate: new Date(startDate),
            });
          }
          startDate = startDate.add(interval, "week");
        }
      }
      this.keyDateList++;
    },
    editOrAddStep(data) {
      const step = {
        isDone: false,
        missions: data.data.missions,
        step: {
          id: data.data.id,
          label: data.data.label,
          isCommon: data.data.isCommon,
        },
      };
      if (data.update) {
        this.interventionForm.steps.splice(data.index, 1, step);
      } else {
        this.interventionForm.steps.push(step);
      }
    },
    editOrAddMission(data) {
      const mission = {
        isDone: false,
        stepId: this.interventionForm.steps[data.stepIndex].step.id,
        mission: {
          id: data.data.id,
          label: data.data.label,
          isCommon: data.data.isCommon,
          device: data.data.device,
        },
      };
      if (data.update) {
        this.interventionForm.steps[data.stepIndex].missions.splice(
          data.index,
          1,
          mission
        );
      } else {
        this.interventionForm.steps[data.stepIndex].missions.push(mission);
      }
    },
    deleteStepInIntervention(id) {
      const missionsList =
        this.interventionForm.steps[
          this.interventionForm.steps.findIndex((elem) => elem.step.id === id)
        ].missions;
      this.deleteStep(id).then(() => {
        this.interventionForm.steps.splice(
          this.interventionForm.steps.findIndex((elem) => elem.step.id === id),
          1
        );
        missionsList.forEach((elem) => {
          this.deleteMission(elem.mission.id);
        });
      });
    },
    deleteMissionInIntervention(stepIndex, id) {
      this.deleteMission(id).then(() => {
        this.steps[stepIndex].missions.splice(
          this.steps[stepIndex].missions.findIndex(
            (elem) => elem.mission.id === id
          ),
          1
        );
      });
    },
    formInterventionEvent(id = 0) {
      if (id) {
        this.fetchIntervention(id).then(() => {
          this.downloadFileInDropZone();
        });
        this.dataOrigine = JSON.stringify(this.interventionForm);
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.interventionForm = {
        id: 0,
        status: 0,
        indications: "",
        observations: "",
        poolId: null,
        interventionTypes: { id: null },
        collaboratorId: null,
        customer: { id: null },
        steps: [],
        files: [],
      };
      this.dataOrigine = JSON.stringify(this.interventionForm);
    },
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    uploadFileAdded(file) {
      if (!file.id && this.interventionForm.id) {
        let formData = new FormData();
        formData.append("file", file);
        this.uploadInterventionFile({
          interventionId: this.interventionForm.id,
          label: file.name,
          file: formData,
        });
      }
    },
    downloadFileInDropZone() {
      this.interventionForm.files.map((elem) => {
        const file = {
          size: elem.fileSize,
          name: elem.label,
          type: "image/png",
          id: elem.id,
        };
        const url = elem.fullPath;
        this.$refs.imgDropZone.manuallyAddFile(file, url);
      });
    },
    removeFile(file) {
      if (this.is_destroying) {
        return;
      }
      if (file.id) {
        this.deleteFile(file.id);
      }
    },
    popupCreateStep(data) {
      this.$bvModal.show("popup-step");
      this.$refs["popupStep"].popupCreateStepEvent(data.id, data.index);
    },
    popupCreateMission(data) {
      this.$bvModal.show("popup-mission");
      if (data.element) {
        this.$refs["popupMission"].popupCreateMissionEvent(
          data.element.mission.id,
          data.stepIndex,
          data.index
        );
      } else {
        this.$refs["popupMission"].popupCreateMissionEvent(
          "",
          data.stepIndex,
          ""
        );
      }
    },
    ...mapActions([
      "createIntervention",
      "createInterventionByRecurence",
      "updateIntervention",
      "fetchCustomer",
      "archiveCustomer",
      "fetchCustomersList",
      "fetchCollaboratorsList",
      "fetchPoolsList",
      "fetchInterventionTypesList",
      "fetchIntervention",
      "updateMissionStep",
      "updateMissionStepIntervention",
      "updateStepIntervention",
      "uploadInterventionFile",
      "deleteStep",
      "deleteMission",
      "deleteFile",
    ]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateIntervention
        .validate()
        .then((success1) => {
          const totalMissions = this.interventionForm.steps.reduce(
            (acc, elem) => {
              return acc + elem.missions.length;
            },
            0
          );
          if (totalMissions == 0) {
            this.$bvToast.toast(
              "Veuillez ajouter au moins une mission à l'intervention",
              {
                title: "Erreur",
                variant: "danger",
                solid: true,
              }
            );
            return;
          } else {
            if (success1) {
              //const dateInter = new Date(this.interventionRecurrent[0].startDate).toISOString();
              if (this.interventionForm.id) {
                this.updateIntervention({
                  dateTime: new Date(
                    this.interventionDate.startDate
                  ).toISOString(),
                  status: this.interventionForm.status,
                  indications: this.interventionForm.indications,
                  observations: this.interventionForm.observations,
                  poolId: this.interventionForm.poolId,
                  interventionTypeId:
                    this.interventionForm.interventionTypes.id,
                  collaboratorId: this.interventionForm.collaboratorId,
                  customerId: this.interventionForm.customer.id,
                  id: this.interventionForm.id,
                }).then(() => {
                  const stepsList = [];
                  for (let i = 0; i < this.steps.length; i++) {
                    const missionsList = [];
                    const stepElement = this.steps[i];
                    stepsList.push({
                      stepId: stepElement.step.id,
                      isDone: stepElement.isDone,
                    });
                    for (let j = 0; j < stepElement.missions.length; j++) {
                      const missionElement = stepElement.missions[j];
                      missionsList.push({
                        missionId: missionElement.mission.id,
                        interventionId: this.interventionForm.id,
                        isDone: missionElement.isDone,
                      });
                    }
                    this.updateMissionStep({
                      stepId: stepElement.step.id,
                      missions: missionsList.map((elem) => elem.missionId),
                    }).then(() => {
                      this.updateMissionStepIntervention({
                        stepId: stepElement.step.id,
                        step: missionsList,
                      });
                    });
                  }
                  this.updateStepIntervention({
                    interventionId: this.interventionForm.id,
                    steps: stepsList,
                  });
                  this.$nextTick(() => {
                    if (this.$route.params.routeOrigine) {
                      this.$tabs.close({
                        to: "/" + this.$route.params.routeOrigine,
                      });
                    } else {
                      this.$tabs.close({ to: "/interventions" });
                    }
                  });
                });
              } else {
                if (this.recurrentInterventionList.length > 1) {
                  this.createIntervention({
                    dateTime: new Date(
                      this.recurrentInterventionList[0].startDate
                    ).toISOString(),
                    status: 0,
                    indications: this.interventionForm.indications,
                    observations: this.interventionForm.observations,
                    poolId: this.interventionForm.poolId,
                    interventionTypeId:
                      this.interventionForm.interventionTypes.id,
                    collaboratorId: this.interventionForm.collaboratorId,
                    customerId: this.interventionForm.customer.id,
                  }).then((res) => {
                    const stepsList = [];
                    for (let i = 0; i < this.steps.length; i++) {
                      const missionsList = [];
                      const stepElement = this.steps[i];
                      stepsList.push({
                        stepId: stepElement.step.id,
                        isDone: false,
                      });
                      for (let j = 0; j < stepElement.missions.length; j++) {
                        const missionElement = stepElement.missions[j];
                        missionsList.push({
                          missionId: missionElement.mission.id,
                          interventionId: res.id,
                          isDone: false,
                        });
                      }
                      Promise.all(this.uploadFiles(res.id));
                      this.updateMissionStep({
                        stepId: stepElement.step.id,
                        missions: missionsList.map((elem) => elem.missionId),
                      }).then(() => {
                        this.updateMissionStepIntervention({
                          stepId: stepElement.step.id,
                          step: missionsList,
                        });
                      });
                    }
                    this.updateStepIntervention({
                      interventionId: res.id,
                      steps: stepsList,
                    }).then(() => {
                      this.createInterventionByRecurence({
                        interventionId: res.id,
                        dates: this.recurrentInterventionList
                          .map((elem) => new Date(elem.startDate).toISOString())
                          .filter(
                            (date) =>
                              date !==
                              new Date(
                                this.recurrentInterventionList[0].startDate
                              ).toISOString()
                          ),
                      }).then(() => {
                        this.$nextTick(() => {
                          if (this.$route.params.routeOrigine) {
                            this.$tabs.close({
                              to: "/" + this.$route.params.routeOrigine,
                            });
                          } else {
                            this.$tabs.close({ to: "/interventions" });
                          }
                        });
                      });
                    });
                  });
                } else {
                  this.createIntervention({
                    dateTime: new Date(
                      this.interventionDate.startDate
                    ).toISOString(),
                    status: 0,
                    indications: this.interventionForm.indications,
                    observations: this.interventionForm.observations,
                    poolId: this.interventionForm.poolId,
                    interventionTypeId:
                      this.interventionForm.interventionTypes.id,
                    collaboratorId: this.interventionForm.collaboratorId,
                    customerId: this.interventionForm.customer.id,
                  }).then((res) => {
                    const stepsList = [];
                    for (let i = 0; i < this.steps.length; i++) {
                      const missionsList = [];
                      const stepElement = this.steps[i];
                      stepsList.push({
                        stepId: stepElement.step.id,
                        isDone: false,
                      });
                      for (let j = 0; j < stepElement.missions.length; j++) {
                        const missionElement = stepElement.missions[j];
                        missionsList.push({
                          missionId: missionElement.mission.id,
                          interventionId: res.id,
                          isDone: false,
                        });
                      }
                      this.updateMissionStep({
                        stepId: stepElement.step.id,
                        missions: missionsList.map((elem) => elem.missionId),
                      }).then(() => {
                        this.updateMissionStepIntervention({
                          stepId: stepElement.step.id,
                          step: missionsList,
                        });
                      });
                    }
                    this.updateStepIntervention({
                      interventionId: res.id,
                      steps: stepsList,
                    });
                    Promise.all(this.uploadFiles(res.id)).then(() => {
                      if (this.$route.params.routeOrigine) {
                        this.$tabs.close({
                          to: "/" + this.$route.params.routeOrigine,
                        });
                      } else {
                        this.$tabs.close({ to: "/interventions" });
                      }
                    });
                  });
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    uploadFiles(id) {
      const files = this.$refs.imgDropZone.getAcceptedFiles();
      const allPromises = [];
      files.forEach((file) => {
        let formData = new FormData();
        formData.append("file", file);
        allPromises.push(
          this.uploadInterventionFile({
            interventionId: id,
            label: file.name,
            file: formData,
          })
        );
      });
      return allPromises;
    },
    archiveCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCustomer(id);
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.interventionForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
    updateSteps(interventionId) {
      const stepIntervention = [];
      //Boucle sur toutes les étapes
      for (let i = 0; i < this.steps.length; i++) {
        const step = [];
        //Ajoute au tableau stepIntervention chaque étape a transmettre à l'action updateStepIntervention
        stepIntervention.push({
          stepId: this.steps[i].id,
          isDone: this.steps[i].isDone,
        });
        //Boucle sur toutes les missions de chaques étapes
        for (let j = 0; j < this.steps[i].missions.length; j++) {
          //Ajoute au tableau steps, chaque étape a transmettre à l'action updateMissionStep
          step.push({
            missionId: this.steps[i].missions[j].id,
            interventionId: interventionId,
            isDone: this.steps[i].missions[j].isDone,
          });
        }
        //Mise à jour du tableau des tâches de chaque étapes liées à l'intervention
        this.updateMissionStepIntervention({
          stepId: this.steps[i].id,
          step: step,
        });
      }
      this.updateStepIntervention({
        interventionId: interventionId,
        steps: stepIntervention,
      });
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingCustomer",
      "interventionTypesList",
      "isLoadingInterventionTypesList",
      "poolsList",
      "customersList",
      "collaboratorsList",
      "isLoadingIntervention",
      "isCreatingIntervention",
      "isUpdatingIntervention",
    ]),
    filteredPools() {
      let newArray = [];
      if (this.interventionForm.customer.id) {
        newArray = this.poolsList.filter(
          (elem) => elem.customerId === this.interventionForm.customer.id
        );
      }
      return newArray;
    },
    interventionForm: {
      get() {
        return this.$store.getters.intervention;
      },
      set(value) {
        return this.$store.commit("SET_INTERVENTION", value);
      },
    },
    steps: {
      get() {
        return this.interventionForm.steps;
      },
      set(value) {
        return (this.interventionForm.steps = value);
      },
    },
  },
  watch: {
    "interventionForm.customer.id": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.interventionForm.poolId = null;
      }
      if (this.interventionForm.id) {
        this.interventionForm.customerId = this.interventionForm.customer.id;
      }
    },
    "interventionForm.isRecurring": function (newVal) {
      if (newVal === false) {
        this.interventionsRecurrent = [];
      }
    },
    numberRecurrent: function () {
      this.generateRecurrenteInterventions();
    },
    dayRecurrent: function () {
      this.generateRecurrenteInterventions();
    },
    timeRecurrent: function () {
      this.generateRecurrenteInterventions();
    },
    dateRangeRecurrentIntervention: function () {
      this.generateRecurrenteInterventions();
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    VSwatches,
    Autocomplete,
    DateRangePicker,
    PopupStep,
    PopupMission,
    nestedDraggable,
    vueDropzone,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.pin-status {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.containerInputDatePicker {
  width: 50%;
  .inputDatePicker div.reportrange-text {
    border-radius: 0.357rem 0px 0px 0.357rem;
  }
  .containerIcon {
    height: 2.714rem;
    width: 40px;
    border-radius: 0px 0.357rem 0.357rem 0px;
    padding: 8.7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 16px;
      width: 16px;
      cursor: pointer;
      color: #fff;
      background-color: #0096c7;
    }
  }
}

.btn {
  display: block;

  .feather:nth-child(2) {
    margin-left: 5px;
  }

  &.add-step {
    display: flex;
    justify-content: space-between;
    width: 20vw;
  }
}

.dz-progress {
  display: none;
}

.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;

  .content-scrollable-sticky-nav {
    white-space: nowrap;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }

      li a.current {
        background: black;
      }
    }
  }
}

.containerRecurrent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#weeks {
  width: 7%;
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
